import { useRouteParams } from '@vueuse/router'
import type { Playback } from '~/types'

/**
 * @file This store is responsible for managing the playbacks profile related states and actions.
 *
 * @todo
 * [x] Get playback by UUID from the API.
 */

export const usePlaybackStore = defineStore('playback', () => {
  const pending = ref(false)
  const uuid = ref(useRouteParams<string>('uuid'))
  const playback = ref<Playback>()

  async function getPlayback() {
    pending.value = true
    const response = await useFetch(`/api/v2/public/playbacks/${uuid.value}`, {
      transform: (data: { data: Playback }) => useCase().toCamelCase(data.data),
      onRequest: () => pending.value = true,
    })

    playback.value = response.data.value
    pending.value = false

    // console.log('playback', playback.value)

    return response
  }

  return {
    playback,
    pending,

    getPlayback,
  }
})
